import React from 'react';
import { FiShoppingBag} from 'react-icons/fi'

export const links = [
  {
    title: 'Pages',
    links: [
      {
        name: 'List Of Stack',
        icon: <FiShoppingBag />,
        path: ''
      },
      // {
      //   name: 'Upload Stack',
      //   icon: <FiShoppingBag />,
      //   path: 'upload-stack'
      // },
    ],
  },
];


